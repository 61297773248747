<template>
    <div class="product1">
        <div class="titleP">
            <p>智合醫 CoDoctor 多功能健康檢測產品</p>
            <p>WHAT WE MAKE</p>
        </div>
        <div class="contentP">
            <div v-for="(item, index) in productIntroList" :key="index">
                <div class="conImg" @mouseenter="handleMouseEnter(item)" @mouseleave="handleMouseLeave(item)">
                    <div style="height: 100%;display: flex;align-items: center;justify-content: center;">
                        <img :src="item.p">
                    </div>
                    <div v-if="item.isMouseLeave" class="mouseClass">
                        <p>{{ item.enterTitle }}</p>
                        <p>{{ item.enterIntro }}</p>
                    </div>
                </div>
                <div class="conPro">
                    <p>{{ item.proName }}</p>
                    <p>{{ item.proIntro }}</p>
                    <p>{{ item.proNumber }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import p1 from '@/assets/icon/202/202/JY02_web.png'
import p2 from '@/assets/icon/202/202/TF02_web.png'
import p3 from '@/assets/icon/202/JYWatch_web@2x.png'
import p4 from '@/assets/icon/202/202/JYE01_web.png'
import p11 from '@/assets/icon/160/160/JY02_pad&phone.png'
import p12 from '@/assets/icon/160/160/JY02_pad&phone-1.png'
import p13 from '@/assets/icon/160/JYWatch_pad&phone@2x.png'
import p14 from '@/assets/icon/160/160/JYE01_pad&phone.png'
export default {
    name: 'product1',
    props: ['screenWidth'],
    data() {
        return {
            productIntroList: [],
        }
    },
    watch: {
        screenWidth(newval, oldval) {
            console.log(newval, oldval, 72);
        }
    },
    mounted() {
        this.getproductIntroList()
    },
    methods: {
        getproductIntroList() {
            this.productIntroList = [
                {
                    p: this.screenWidth > 1025 ? p1 : p11,
                    proName: 'CoDoctor Pro',
                    proIntro: '智合醫多功能檢測儀 專業版',
                    proNumber:'衛部醫器製字第 008214 號',
                    isMouseLeave: false,//判断鼠标移入移出
                    enterTitle: '讓健康管理更加全面',
                    enterIntro: '多合一監測功能，適用於醫療院所和企業機構等場域，支援遠距健康監測和視訊咨詢，提升醫療服務質量、員工健康管理與工作效率，降低工作風險。',
                }, {
                    p: this.screenWidth > 1025 ? p2 : p12,
                    proName: 'CoDoctor Home',
                    proIntro: '智合醫多功能檢測儀 居家版',
                    proNumber:'衛部醫器製字第 008240 號',
                    isMouseLeave: false,//判断鼠标移入移出
                    enterTitle: '小機身，大能量',
                    enterIntro: '以「家」為健康照護核心，小小一台便能夠輕鬆完成檢測多項身體指標；貼近用戶的使用者體驗榮獲專利界最高榮譽「111 年國家發明創作獎」。'
                }, {
                    p: this.screenWidth > 1025 ? p3 : p13,
                    proName: 'CoDoctor Watch I',
                    proIntro: '智合醫智慧健康手錶',
                    isMouseLeave: false,//判断鼠标移入移出
                    enterTitle: '全天候守候',
                    enterIntro: '自動檢測和提醒功能，適合遠距照護與大型運動賽事健康監測等應用場域。'
                }, {
                    p: this.screenWidth > 1025 ? p4 : p14,
                    proName: 'CoDoctor Eye',
                    proIntro: '智合醫手持式智慧眼底影像擷取儀',
                    proNumber:'衛部醫器製字第 008251 號',
                    isMouseLeave: false,//判断鼠标移入移出
                    enterTitle: '一張眼底圖，讓AI算病',
                    enterIntro: '結合AI眼底影像判讀技術，輕鬆進行眼底影像掃描輔助醫師判斷潛在病灶；符合人因工程的機身設計且配有醫療級矽膠眼罩，讓使用者舒適便捷地使用。'
                }
            ]
        },
        handleMouseEnter(item) {
            this.productIntroList.forEach(e => {
                if (e.proName === item.proName) {
                    e.isMouseLeave = !e.isMouseLeave
                }
            })
        },
        handleMouseLeave(item) {
            this.productIntroList.forEach(e => {
                if (e.proName === item.proName) {
                    e.isMouseLeave = !e.isMouseLeave
                }
            })
        }
    }
}
</script>

<style lang="less">
.product1 {
    width: 80%;
    margin: 0px auto;
    padding-top: 160px;

    .titleP {
        text-align: left;

        p:first-child {
            font-family: "Noto Sans CJK TC";
            font-weight: 500;
            font-size: 32px;
            height: 32px;
            line-height: 32px;
            color: #333333;
        }

        p:nth-child(2) {
            font-family: 'Syncopate';
            font-weight: 400;
            font-size: 24px;
            height: 24px;
            line-height: 24px;
            color: #0052A4;
            margin-top: 16px;
            opacity: 0.8;
        }
    }

    .contentP {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
        margin-bottom: 240px;

        .conImg {
            border: 4px solid #F6F6F6;
            border-radius: 20px;
            height: 300px;
            width: 240px;
            position: relative;
            background-color: #ffffff;
        }

        .conPro {
            color: #333333;
            font-family: "Noto Sans CJK TC";
            margin-top: 25px;

            p:first-child {
                font-size: 20px;
                height: 20px;
                line-height: 140%;
                font-weight: 500;
            }

            p:nth-child(2) {
                font-size: 16px;
                height: 16px;
                line-height: 140%;
                font-weight: 400;
                margin-top: 10px;
            }

            p:nth-child(3){
                font-size: 16px;
                height: 16px;
                font-weight: 400;
                line-height: 140%;
                margin-top: 10px;
                font-family: Noto Sans CJK TC;
                color: #9B9B9B;
            }
        }

        .mouseClass {
            position: absolute;
            top: 0;
            background-color: rgba(13, 38, 63, 0.88);
            width: 100%;
            height: 100%;
            border-radius: 20px;
            color: #FFFFFF;
            font-family: "Noto Sans CJK TC";
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                width: 80%;
            }

            p:first-child {
                font-size: 18px;
                font-weight: 500;
                line-height: 18px;
                height: 18px;
                text-align: left;
                letter-spacing: 1px;
            }

            p:nth-child(2) {
                font-size: 16px;
                font-weight: 400;
                line-height: 22.4px;
                text-align: justify;
                margin-top: 12px;
                letter-spacing: 1px;
            }
        }
    }
}

@media only screen and (min-width: 1439px) {
    .product1 {
        width: 1440px;
        height: 740px;

        .titleP {
            width: 1152px;
            margin: 0 auto;
        }

        .contentP {
            width: 1152px;
            margin: 100px auto 240px auto;
            display: flex;
            justify-content: space-evenly;

            .conImg {
                img {
                    width: 202px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1023px) and (max-width:1439px) {
    .product1 {
        width: 100%;
        height: 668px;

        .titleP {
            width: 80%;
            margin: 0 auto;
        }

        .contentP {
            width: 82%;
            margin: 100px auto;

            .conImg {
                height: 240px;
                width: 192px;
                position: relative;

                img {
                    width: 160px;
                }
            }

            .conPro {
                font-family: Noto Sans CJK TC;
                p:first-child {
                    font-size: 18px;
                    height: 18px;
                    line-height: 18px;
                }

                p:nth-child(2) {
                    height: 100%;
                    line-height: 22.4px;
                    width: 200px;
                }
                p:nth-child(3) {
                    height: 22px;
                    line-height: 22.4px;
                    width: 200px;
                    color: #9B9B9B;
                }
            }

            .mouseClass {
                border-radius: 20px;

                p:first-child {
                    font-size: 16px;
                    line-height: 16px;
                    height: 16px;
                    width: 160px;
                    letter-spacing: 0px;
                }

                p:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    text-align: justify;
                    margin-top: 12px;
                }
            }
        }
    }
}

@media only screen and (min-width: 767px) and (max-width:1023px) {
    .product1 {
        width: 568px;
        padding-top: 100px;
        height: 840px;

        .titleP {
            text-align: center;
        }

        .contentP {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            padding-top: 80px;
            padding-bottom: 100px;
            margin: 0;

            .conImg {
                border: 4px solid #F6F6F6;
                border-radius: 12px;
                height: 200px;
                width: 240px;
                position: relative;

                img {
                    width: 160px;
                }
            }

            .conPro {
                margin: 14px 0 35px 0;
                font-family: Noto Sans CJK TC;

                p:first-child {
                    font-size: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 500;
                }

                p:nth-child(2) {
                    font-size: 16px;
                    height: 22px;
                    line-height: 22.4px;
                    font-weight: 400;
                    margin-top: 8px;
                }
                p:nth-child(3) {
                    font-size: 16px;
                    height: 22px;
                    line-height: 22.4px;
                    font-weight: 400;
                    margin-top: 8px;
                    color: #9B9B9B;

                }
            }

            .mouseClass {
                border-radius: 12px;

                p:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    text-align: justify;
                    margin-top: 12px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .product1 {
        height: 1450px;
        padding-top: 30px;

        .titleP {
            text-align: center;
            width: 100%;

            p:first-child {
                font-size: 24px;
                height: 68px;
                width: 263px;
                line-height: 33.6px;
                margin: 0 auto;
            }

            p:nth-child(2) {
                font-size: 16px;
                height: 16px;
                line-height: 16px;
                color: #0052A4;
                margin-top: 10px;
                opacity: 0.8;
            }
        }

        .contentP {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 40px;

            div {
                .conImg {
                    border: 2px solid #F6F6F6;
                    border-radius: 12px;
                    height: 200px;
                    width: 241px;
                    position: relative;

                    img {
                        width: 160px;
                    }
                }

                .conPro {
                    margin: 14px 0 35px 0;
                    font-family: Noto Sans CJK TC;

                    p:first-child {
                        font-size: 16px;
                        height: 16px;
                        line-height: 16px;
                        font-weight: 500;
                    }

                    p:nth-child(2) {
                        font-size: 14px;
                        height: 20px;
                        line-height: 19.6px;
                        font-weight: 400;
                        margin-top: 4px;
                    }
                    p:nth-child(3) {
                        font-size: 14px;
                        height: 20px;
                        line-height: 19.6px;
                        font-weight: 400;
                        margin-top: 4px;
                        color: #9B9B9B;
                    }
                }
            }



            .mouseClass {
                border-radius: 12px;

                p:nth-child(2) {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    text-align: justify;
                    margin-top: 12px;
                }
            }

        }
    }
}</style>